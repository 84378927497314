import React from "react"
import { graphql, PageProps, Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"
import { Layout } from "../components/Layout"

const Category = styled.h1`
  font-family: PirataOne;
  font-size: 3rem;
`

const Subcategory = styled(GatsbyLink)`
  color: #111;
  font-family: PirataOne;
  font-size: 2rem;
  text-decoration: none;
  margin: 0.5rem;
`

const PostContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
`

const Post = styled(GatsbyLink)`
  color: #111;
  margin: 0.5rem 1rem;
  text-decoration: none;

  margin: 1rem;
  flex: 1;
  min-width: 256px;
  max-width: 512px;
`

const PostTitle = styled.h2`
  font-family: PirataOne;
  font-size: 1rem;
`

interface QueryProps {
  item: {
    name: string
    subcategories: {
      name: string
      slug: string
      posts: {
        slug: string
        image: {
          title: string
          file: {
            name: string
          }
        }
      }[]
    }[]
  }
}

const CategoryTemplate: React.FC<PageProps<QueryProps>> = ({ data }) => {
  return (
    <Layout title={data.item.name} breadcrumbs={[{ name: data.item.name }]}>
      <Category>{data.item.name}</Category>
      {data.item.subcategories?.map(subcategory => {
        if (!subcategory.posts) return null
        return (
          <React.Fragment key={subcategory.slug}>
            <Subcategory to={`/${subcategory.slug}/`} aria-label={subcategory.name}>
              {subcategory.name}
            </Subcategory>
            <PostContainer>
              {subcategory.posts.slice(0, 2).map(item => (
                <Post key={item.slug} to={`/${item.slug}/`} aria-label={item.image.title}>
                  <PostTitle>{item.image.title}</PostTitle>
                  <picture>
                    <source srcSet={`/images/${item.image.file.name.replace(".jpeg", ".webp")}`} />
                    <img src={`/images/${item.image.file.name}`} alt={item.image.title} />
                  </picture>{" "}
                </Post>
              ))}
            </PostContainer>
          </React.Fragment>
        )
      })}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    item: contentfulCategory(id: { eq: $id }) {
      name
      subcategories {
        name
        slug
        posts: image_post {
          slug
          image {
            title
            file {
              name: fileName
            }
          }
        }
      }
    }
  }
`

export default CategoryTemplate
